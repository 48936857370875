// ThemeRoutes.js

import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, AuthenticationRoutes]);
}



/* 
import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/login" replace />,
    }, 
    MainRoutes,
    AuthenticationRoutes,
  ]);
}   */


   /* 


// ThemeRoutes.js
 import { useRoutes, Navigate } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

const isAuthenticated = () => {
  const token = localStorage.getItem('userToken');
  return token && token !== '';
};

export default function ThemeRoutes() {
  const auth = isAuthenticated(); // เรียกใช้ฟังก์ชันเพื่อตรวจสอบสถานะ authentication

  return useRoutes([
    {
      path: '/',
      element: auth ? <MainRoutes /> : <Navigate to="/login" replace />,
    },
    MainRoutes,
    AuthenticationRoutes,
  ]);
}   */
