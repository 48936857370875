// assets
import { IconKey } from '@tabler/icons';

// constant
const icons = {
  IconKey
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'pages',
  title: 'AdminPage',
  caption: 'Management',
  type: 'group',
  roles: ["admin"], // Both user and admin can see this

  children: [
    {
      id: 'usermanagement',
      title: 'จัดการผู้ใช้งาน',
      type: 'item',
      url: '/admin',
      icon: icons.IconKey,
     // target: true
      breadcrumbs: false

    
    }
  ]
};
export default pages;
