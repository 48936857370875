import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));
const UtilsPageError = Loadable(lazy(() => import('views/utilities/PageError')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/login',
      element: <AuthLogin />
    },
    {
      path: '*',
      element: <UtilsPageError />
    }

  ]
};
 
/* const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    { path: '/login', element: <AuthLogin /> }, // เส้นทางหน้าแรก

 
    { path: '*', element: <UtilsPageError /> }, // จับเส้นทางที่ไม่ตรงกับเงื่อนไขใดๆ
  ]
};
 */


export default AuthenticationRoutes;
 


