import { useState, useEffect } from "react";

const TitleSection = () => {
  // useState เพื่อเก็บขนาดตัวหนังสือ
  const [fontSize, setFontSize] = useState("25px");

  useEffect(() => {
    // ฟังก์ชันที่เรียกเมื่อหน้าจอเปลี่ยนขนาด
    const handleResize = () => {
      // ถ้าหน้าจอมีความกว้างน้อยกว่าหรือเท่ากับ 768 pixels, ให้ลดขนาดตัวหนังสือ
      if (window.innerWidth <= 768) {
        setFontSize("20px");
      } else {
        // ถ้ามากกว่า 768 pixels, ให้ใช้ขนาดปกติ
        setFontSize("25px");
      }
    };

    // เพิ่ม event listener
    window.addEventListener("resize", handleResize);

    // ตรวจสอบขนาดของหน้าจอเมื่อ component นั้นถูกโหลด
    handleResize();

    // ลบ event listener เมื่อ component นั้นถูก unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        display: "flex", // Use flexbox layout
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        height: "100%", // Set the height to fill the container (adjust as needed)
        paddingLeft: "15px",
      }}
    >
      {" "}
   
      <h2
        style={{
          fontFamily: "Sarabun, sans-serif",
          fontSize: fontSize,
          textTransform: "uppercase",
          textShadow: "2px 1px #eef2f6",
          textAlign: "center", // Ensure text itself is centered if it breaks into multiple lines
        }}
      >
        ระบบจัดเก็บเอกสาร
      </h2>
    </div>
  );
};

export default TitleSection;
