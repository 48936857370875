// assets
import { IconTrash, IconHelp } from '@tabler/icons';

// constant
const icons = { IconTrash, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: 'sample-docs-roadmap',
  type: 'group',
  roles: ["user", "admin"], // Both user and admin can see this

  children: [

    {
      id: 'support',
      title: 'Support',
      type: 'item',
      url: '/support',
      icon: icons.IconHelp,
      breadcrumbs: false
      //external: true,
      //target: true
    }
  ]
};

export default other;
