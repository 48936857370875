
// MenuList.js
import NavGroup from "./NavGroup";
import menuItems from "menu-items"; // Make sure the path is correct

const MenuList = () => {
  const userRole = sessionStorage.getItem("userRole"); // Or get this from context/global state

  // Filter menu items based on the role
  const filteredItems = menuItems.items.filter((item) =>
    item.roles.includes(userRole)
  );

  // Map over the filtered items and create NavGroup components
  const navGroups = filteredItems.map((item, index) => (
    <NavGroup key={index} item={item} />
  ));

  return <div>{navGroups}</div>;
};

export default MenuList;
