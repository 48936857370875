// assets
import { IconFileSearch, IconPrinter, IconShadow, IconFolders, IconTrash, IconFileDescription, IconFileAnalytics } from '@tabler/icons';

// constant
const icons = {
  IconFileSearch,
  IconPrinter,
  IconShadow,
  IconFolders,
  IconTrash,
  IconFileDescription,
  IconFileAnalytics
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //
const utilities = {
  id: 'utilities',
  //title: 'Utilities',
  type: 'group',
  roles: ["user", "admin"], // Both user and admin can see this

  children: [
    {
      id: 'alldoc',
      title: 'ทะเบียนเอกสารทั้งหมด',
      type: 'collapse',
      icon: icons.IconFolders,
      children: [
        {
          id: 'Inputregister',
          title: 'ทะเบียนเอกสารรับ',
          type: 'item',
          url: '/inputregister',
          breadcrumbs: false
        },
        {
          id: 'Sendregister',
          title: 'ทะเบียนเอกสารส่ง',
          type: 'item',
          url: '/sendregister',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'register',
      title: 'ลงทะเบียนรับส่ง',
      type: 'collapse',
      icon: icons.IconFileDescription,
      children: [
        {
          id: 'inputdocument',
          title: 'ลงรับ/สร้างเอกสาร',
          type: 'item',
          url: '/inputdocument',
          breadcrumbs: false
        },
        {
          id: 'senddocument',
          title: 'สร้างเอกสารส่ง',
          type: 'item',
          //external: true,
          //target: '_blank',
          url: '/senddocument',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'searchdocument',
      title: 'ค้นหาขั้นสูง',
      type: 'item',
      url: '/searchdocument',
      icon: icons.IconFileSearch,
      breadcrumbs: false
    },
    {
      id: 'report',
      title: 'รายงานสถิติ',
      type: 'item',
      url: '/report',
      icon: icons.IconFileAnalytics,
      breadcrumbs: false
    }
    /* {
      id: 'util-shadow',
      title: 'ถังขยะ',
      type: 'item',
      url: '/utils/util-shadow',
      icon: icons.IconTrash,
      breadcrumbs: false
    } */
  ]
};

export default utilities;
