import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

const AdminRoute = ({ children }) => {
  const userRole = sessionStorage.getItem('userRole');

  if (userRole !== 'admin') {
    return <Navigate to="/PageError" replace />;
  }

  return children;
};

AdminRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AdminRoute;
