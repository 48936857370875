//import React from "react";
function UserGreeting() {
    const firstname = sessionStorage.getItem("userFirstname") || "Guest";
    const lastname = sessionStorage.getItem("userLastname") || ""; // ดึง lastname

    return (
        <div> {firstname} {lastname}</div> // แสดง firstname และ lastname
    );
}

export default UserGreeting;
